// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2021_12-module--afterCaption--cbd2a";
export var columnWrapper = "maine_digest_2021_12-module--columnWrapper--8304f";
export var dateline = "maine_digest_2021_12-module--dateline--882f1";
export var heading = "maine_digest_2021_12-module--heading--bbe3f";
export var headingLogo = "maine_digest_2021_12-module--headingLogo--1c5fc";
export var headingWrapper = "maine_digest_2021_12-module--headingWrapper--4e79b";
export var heroImage = "maine_digest_2021_12-module--heroImage--28e87";
export var instance = "maine_digest_2021_12-module--instance--ed57a";
export var latestnewsarticleheadline = "maine_digest_2021_12-module--latestnewsarticleheadline--0a5b5";
export var maineDigest = "maine_digest_2021_12-module--maineDigest--e057f";
export var photo = "maine_digest_2021_12-module--photo--33971";
export var photoCaption = "maine_digest_2021_12-module--photoCaption--5e79c";
export var photosWrapper = "maine_digest_2021_12-module--photosWrapper--e047c";
export var rightCaptions = "maine_digest_2021_12-module--rightCaptions--3b088";
export var rightPhoto = "maine_digest_2021_12-module--rightPhoto--531b0";
export var rightPhotoCaption = "maine_digest_2021_12-module--rightPhotoCaption--5641d";
export var subheading = "maine_digest_2021_12-module--subheading--27324";
export var textWrapper = "maine_digest_2021_12-module--textWrapper--6c545";
export var whatsNew = "maine_digest_2021_12-module--whatsNew--8bfa9";